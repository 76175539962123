export default {
	en : {
		auth : {
			title : 'Login Admin',
			login : 'Login',
			success : 'Authorization Success',
			failed : 'Authorization Failed',
			username : 'Username',
			password : 'Password',
			forgotPassword : 'Lupa Password ?',
			logout : 'Log Out'
		},
		account : {
			title : 'Edit Management Account',
			buttonLabel : 'Create Account',
			titleForm : 'Create Management Account',
		},
		input : {
			name : 'Name',
			username : 'Username',
			password : 'Password',
			confirmPassword : 'Confirm Password',
			description : 'Description',
			email : 'Email',
			phone : 'Phone',
			note : 'Note',
			gender : 'Gender',
			chooseAccount : 'Pilih Jenis Account',
			headline : 'Headline',
			title : 'Judul',
			articleCategory : 'Kategori Artikel',
			articleResource : 'Sumber Artikel Utama',
			articleTag : 'Tag Artikel',
			channelName : 'Nama Channel',
			channelDescription : 'Deskripsi Channel',
			donationTittle : 'Judul Donasi',
			donationDescription : 'Deskripsi Donasi',
			donationTarget : 'Target Donasi',
			donationOrganizer : 'Nama Penyelenggara',
			donationEnd : 'Berakhir pada',
			donationVerified : 'Diverifikasi Oleh',
			donationReceiver : 'Nama Wali / Penerima Uang',
			channel : 'Channel',
		},
		button : {
			upload : 'Upload Foto',
			save : 'Save',
			login : 'Login',
			logout : 'Log Out',
			preview : 'Preview',
			submit : 'Submit',
			donationCreate : 'Buat Donasi',
			donationAdd : 'Tambah Donasi',
			donationEdit : 'Edit Donasi',
			accountCreate : 'Create Account',
			playbackLive : 'Start New Live',
			postProduct : 'Post Product',
			channelAdd : 'Buat Channel',
			channelEdit : 'Edit Channel',
			userCreate :'Create Account',
			postFeed : 'Post Feed',
			adAdd : 'Buat Ad',
			adEdit : 'Edit Ad',
		},
		title : {
			channel : 'List Channel',
			channelAdd : 'Buat Channel',
			channelEdit : 'Edit Channel',
			donation : 'List Donasi',
			donationAdd : 'Buat Donasi',
			donationEdit : 'Ubah Detail Donasi',
			account : 'List Management Account',
			accountCreate : 'Create Management Account',
			accountEdit : 'Edit Management Account',
			user: 'List User Account',
			userCreate : 'Create User',
			ad : 'List Ad',
			adAdd : 'Buat Ad',
			adEdit : 'Edit Ad',
		},
		upload : 'Upload Foto',
		save : 'Save'
	}
}