var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item.order)?_c('HeaderContent',{attrs:{"label":"Detail Komplain","list":[
        {
          text: 'Komplain',
        },
        {
          text: 'List Komplain',
          disabled: true,
        },
        {
          text: _vm.item.order.noInvoice,
          disabled: true,
        },
      ]}},[(_vm.item.status == 'NEW')?_c('div',[_c('custom-button',{on:{"click":_vm.openDialog}},[_vm._v("Komplain Tidak Valid")]),_c('custom-button',{staticClass:"ml-4",attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.handleProcessComplaint}},[_vm._v("Proses Komplain")])],1):_vm._e(),(_vm.item.status == 'PROCESS')?_c('div',[_c('custom-button',{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.handleFinishComplaint}},[_vm._v("Mediasi Selesai")])],1):_vm._e()]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"lg":"6"}},[(_vm.item.order)?_c('Detail-Product',{attrs:{"item":_vm.item}}):_vm._e()],1),(_vm.item.status != 'NEW')?_c('v-col',{attrs:{"lg":"6"}},[_c('Detail-Mediation',{attrs:{"item":_vm.item,"payload":_vm.payload,"isDisabled":_vm.isDisabled}})],1):_vm._e()],1),_c('Dialog-Text-Area',{attrs:{"value":_vm.adminReport,"dialog":_vm.dialog,"loading":_vm.loadingReject,"rules":"required","title":"Komplain tidak valid","name":"Admin Report"},on:{"input":_vm.input,"handleClick":_vm.handleRejectComplaint,"closeDialog":_vm.closeDialog}}),_c('v-snackbar',{attrs:{"top":"","right":"","color":"success"},model:{value:(_vm.alertSuccess),callback:function ($$v) {_vm.alertSuccess=$$v},expression:"alertSuccess"}},[_vm._v(" Proses Mediasi Berhasil ")]),_c('v-snackbar',{attrs:{"top":"","right":"","color":"error"},model:{value:(_vm.alertFailed),callback:function ($$v) {_vm.alertFailed=$$v},expression:"alertFailed"}},[_vm._v(" Proses Mediasi Gagal ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }