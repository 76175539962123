<template>
  <v-dialog v-model="dialog" persistent width="484">
    <v-card class="text-center pa-8">
      <v-card-title class="d-flex justify-center">
        <span v-text="title"></span>
      </v-card-title>
      <v-card-text>
        <span v-text="description"></span>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <custom-button @click="closeDialog">Cancel</custom-button>
        <custom-button
          color="primary"
          class="white--text ml-6"
          @click="handleConfirm"
          :loading="loading"
          >Confirm</custom-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    dialog: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  methods: {
    handleConfirm() {
      this.$emit("handleConfirm");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
