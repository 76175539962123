export default {
  namespaced: true,
  state: {
    pathRecordedLive: "admin/recorded-live",
    lives: [],
    totalPages: 0,
    totalElements: 0,
    sortBy : null,
  },
  mutations: {
    setSortBy(state, payload) {
      state.sortBy = payload;
    },
    setLives(state, payload) {
      state.lives = payload;
    },
    setTotalPages(state, payload) {
      state.totalPages = payload;
    },
    setTotalElements(state, payload) {
      state.totalElements = payload;
    },
  },
  actions: {
    fetchRecordedLives({ state, dispatch, commit }, payload) {
      const data = {
        url: `${state.pathRecordedLive}`,
        params: {
          ...payload,
        },
      };
      return dispatch("getWithToken", data, { root: true })
        .then((response) => {
          const responseData = response.data.data;
          const content = responseData.content;
          const totalPages = responseData.totalPages;
          const totalData = responseData.totalElements;
          const contentWithIndex = content.map((c, idx) => {
            return {
              ...c,
              index: idx,
            };
          });
          commit("setLives", contentWithIndex);
          commit("setTotalPages", totalPages);
          commit("setTotalElements", totalData);
          return responseData;
        })
        .catch((err) => {
          commit("setLives", []);
          commit("setTotalPages", 0);
          commit("setTotalElements", 0);
          throw err;
        });
    },
    startLiveStreaming({ state, dispatch }, payload) {
      const params = {
        url: `${state.pathRecordedLive}/start`,
        data: {
          ...payload,
        },
      };
      return dispatch("postWithToken", params, { root: true })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    stopLiveStreaming({ state, dispatch }, payload) {
      const params = {
        url: `${state.pathRecordedLive}/${payload.roomId}/stop`,
      };
      return dispatch("postWithToken", params, { root: true })
          .then((response) => {
            return response;
          })
          .catch((err) => {
            throw err;
          });
    },
  },
};
