export default {
    namespaced: true,
    state: {
        pathTags: "admin/tags",
        videoTags: [],
    },
    mutations: {
        setVideoTags(state, payload) {
            state.videoTags = payload
        },
    },
    actions: {
        async getAllTags({state}) {
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .get(`${state.pathTags}/all`);
                return response;
            } catch (error) {
                return error;
            }
        },
        async createTags({state}, payload) {
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .post(state.pathTags, payload);
                return response;
            } catch (error) {
                return error;
            }
        },
        async deleteTags({state}, payload) {
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .delete(`${state.pathTags}/${payload}`);
                return response;
            } catch (error) {
                return error;
            }
        },
        async editTags({state}, payload) {
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .put(`${state.pathTags}/${payload.tagList[0].id}`, payload);
                return response;
            } catch (error) {
                return error;
            }
        },
        async getFeedAndTags({state}, payload) {
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .get(`${state.pathTags}/social`, {
                        params: {
                            socialId: payload.socialId
                        },
                    });
                return response;
            } catch (error) {
                return error;
            }
        },
        async relatFeedAndTags({state}, payload) {
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .post(`${state.pathTags}/social/${payload.socialId}`, payload);
                return response;
            } catch (error) {
                return error;
            }
        },
        async batchGetFeedAndTags({ state, dispatch, commit }, payload) {
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .post(`${state.pathTags}/social/batch`, payload);

                const responseData = response.data.data;
                commit("setVideoTags", responseData);
                return responseData;
            } catch (error) {
                return error;
            }
        },
    },
};
