<template>
  <div>
    <Header-Content label="Reported DM" :list="crumbs"></Header-Content>

    <div class="filter__card">
      <FilterDate/>
      <div style="width: 200px">
        <k-input
            :disabled="false"
            placeholder="reporter"
            v-model="reporterKeyword"
            @blur="inputReporterName"
        ></k-input>
      </div>
      <div style="width: 200px">
        <k-input
            :disabled="false"
            placeholder="reported"
            v-model="reportedKeyword"
            @blur="inputReportedName"
        ></k-input>
      </div>
      <div v-if="showReportContent" style="width: 200px">
        <k-input
            :disabled="false"
            placeholder="report content"
            v-model="reportContentKeyword"
            @blur="inputReportContentKeyword"
        ></k-input>
      </div>
      <div style="width: 200px">
        <k-select
            :disabled="false"
            placeholder="Select report content"
            v-model="messageTypeKeyword"
            @input="inputMessageType"
            :items="messageTypeOptions"
        ></k-select>
      </div>
      <custom-button
          color="kellygreen"
          class="white--text"
          size="x-medium"
          @click="searchReportList"
      >
        Query
      </custom-button>
    </div>

    <v-data-table
        :headers="headers"
        :items="reports"
        disable-filtering
        disable-sort
        hide-default-footer
    >
      <template v-slot:[`item.reportContent`]="{ item }">
        <div v-if="item.reportMsgType === messageTypeOptions[0].value"> <!-- Text -->
          <span>{{ item.reportContent}}</span>
        </div>
        <div v-else-if="item.reportMsgType === messageTypeOptions[1].value"
             class="content-center image-column"
             @click="openDialogImage(item)">
          <v-img width="100%" height="100%" :src="item.imageUrl" />
        </div>
        <div v-else-if="item.reportMsgType === messageTypeOptions[2].value"
             class="content-center image-column"
             @click="openDialogImage(item)">
          <video :src="item.videoUrl"  height="100%"></video>
        </div>
        <div v-else>
          <span>{{ item.reportMsgType}}</span>
        </div>
      </template>
      <template v-slot:[`item.reporterName`]="{ item }">
        <span>{{ item.reporterName }}</span>
      </template>
      <template v-slot:[`item.reportedName`]="{ item }">
        <span>{{ item.reportedName }}</span>
      </template>
      <template v-slot:[`item.reportMsgType`]="{ item }">
        <span>{{ item.reportMsgType }}</span>
      </template>
      <template v-slot:[`item.reportTime`]="{ item }">
        <span>{{ formatReportTime(item.reportTime) }}</span>
      </template>
    </v-data-table>
    <v-pagination
        class="d-flex justify-end"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        v-model="page"
        :length="totalPages"
        @input="handleGetReportedList"
        :total-visible="6"
    ></v-pagination>

    <v-dialog v-model="isVideoDialogOpen" width="600">
      <v-card class="text-center pa-8">
        <v-img
            v-if="mediaType === 'image' "
            :src="imageDialog">
        </v-img>
        <video
            v-else
            :src="imageDialog"
            controls
            width="100%"
            height="100%"
        ></video>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import HeaderContent from "@/containers/HeaderContent/index.vue";
import FilterDate from "./filterDate.vue";
import ItemComponent from "@/views/Post/Draft/adminView/itemData.vue";

export default {
  components: {
    ItemComponent,
    FilterDate,
    HeaderContent,
  },
  computed: {
    ...mapState({
      paramsDate: (state) => state.dmReport.paramsDate,
      displayDate: (state) => state.dmReport.displayDate,
    }),
    showReportContent() {
      const isVisible = this.messageTypeKeyword.value !== 'TIMImageElem' && this.messageTypeKeyword.value !== 'TIMVideoFileElem';
      this.payload.reportContent = '';
      return isVisible;
    },
    isTextSelected() {
      return this.selectedType === 'TIMTextElem';
    },
  },
  data() {
    return {
      crumbs: [
        {
          text: "Reported DM",
          disabled: true,
        },
      ],
      headers: [
        {
          text: 'Report Content',
          value: 'reportContent',
          width: "300",
          class: "whitesnow",
        },{
          text: 'Reporter',
          value: 'reporterName',
          width: "150",
          class: "whitesnow",
        },{
          text: 'Reported',
          value: 'reportedName',
          width: "150",
          class: "whitesnow",
        },{
          text: 'Message Type',
          value: 'reportMsgType',
          width: "150",
          class: "whitesnow",
        },{
          text: 'Report Time',
          value: 'reportTime',
          width: "100",
          class: "whitesnow",
        },
      ],
      reports: [],
      totalPages: 0,
      page: 1,
      payload: {
        reporterName:"",
        reportedName:"",
        reportContent:"",
        messageType:"TIMTextElem",
        startTime:"",
        endTime:"",
        pageSize: 10,
        pageNum: 1,
      },
      reporterKeyword: "",
      reportedKeyword: "",
      reportContentKeyword: "",
      selected:[],
      messageTypeKeyword: { label: 'Text', value: 'TIMTextElem' },
      messageTypeOptions: [
        { label: 'Text', value: 'TIMTextElem' },
        { label: 'Image', value: 'TIMImageElem' },
        { label: 'Video', value: 'TIMVideoFileElem' },
      ],
      isVideoDialogOpen: false,
      mediaType : '',
      imageDialog: "",
    }
  },
  created() {
    this.handleGetReportedList();
  },
  methods: {
    ...mapActions({
      getReportRelationshipsList: "dmReport/getReportRelationshipsList",
    }),
    ...mapMutations({
    }),
    async handleGetReportedList(p){
      this.payload.pageNum = p || 1;
      const response = await this.getReportRelationshipsList(this.payload);
      if(response.status === 200){
        this.reports = response.data.data.content;
        this.totalPages = Math.ceil(response.data.data.totalElements / this.payload.pageSize);
      }
    },
    getPayloadDate(data) {
      this.payload.startAt = data.startAt
      this.payload.endAt = data.endAt
    },
    searchReportList(){
      if( this.paramsDate.length === 2 ){
        this.payload.startTime = new Date(this.paramsDate[0]+'T00:00:00+07:00').getTime();
        this.payload.endTime = new Date(this.paramsDate[1]+'T23:59:59+07:00').getTime();
      }else {
        this.payload.startTime = "";
        this.payload.endTime = "";
      }
      this.payload.pageNum = 1;
      this.payload.pageSize = 10;
      this.handleGetReportedList();
      this.page = 1;
    },
    inputReporterName(){
      this.payload.reporterName = this.reporterKeyword
    },
    inputReportedName(){
      this.payload.reportedName = this.reportedKeyword
    },
    inputReportContentKeyword(){
      this.payload.reportContent = this.reportContentKeyword
    },
    inputMessageType(value){
      this.payload.messageType = this.messageTypeKeyword.value
    },
    formatReportTime(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    openDialogImage(item) {
      if(item.reportMsgType === this.messageTypeOptions[1].value){
        this.imageDialog = item.imageUrl;
        this.mediaType = 'image';
      }else if(item.reportMsgType === this.messageTypeOptions[2].value){
        this.imageDialog = item.videoUrl;
        this.mediaType = 'video';
      }
      this.isVideoDialogOpen = true;
    },
  },
}
</script>

<style lang="scss" src="./filter.scss"></style>