<template>
  <v-app>
		<CoreView/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>
