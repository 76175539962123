import { render, staticRenderFns } from "./itemData.vue?vue&type=template&id=ae698696"
import script from "./itemData.vue?vue&type=script&lang=js"
export * from "./itemData.vue?vue&type=script&lang=js"
import style0 from "./itemData.vue?vue&type=style&index=0&id=ae698696&prod&lang=scss&module=feed"
import style1 from "../../style.scss?vue&type=style&index=1&prod&lang=scss&module=ad&external"




function injectStyles (context) {
  
  this["feed"] = (style0.locals || style0)
this["ad"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports