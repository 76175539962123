export default {
    namespaced: true,
    state: {
        pathCommonConfig: "common-config",
    },
    mutations: {

    },
    actions: {
        async getCommonConfigAll({ state }, payload){
            let response;
            try {response = await this._vm
                .$httpWithToken()
                .get(`${state.pathCommonConfig}/all`,
                    {
                        params: {
                            size: payload.size,
                            page: payload.page-1
                        }
                    }
                )
                return response;
            }catch (error){
                return error;
            }
        },
        async getCommonConfigByKey({ state }, payload){
            let response;
            try {
                response = await this._vm
                .$httpWithToken()
                .get(`${state.pathCommonConfig}/${payload.key}`,
                    {
                        params: {
                            size: payload.size,
                            page: payload.page-1
                        }
                    }
                )
                return response;
            }catch (error){
                return error;
            }
        },
        async deleteCommonConfig({ state }, payload){
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .delete(`${state.pathCommonConfig}/${payload.key}`);
                return response;
            }catch (error){
                return error;
            }
        },
        async editCommonConfig({ state }, payload){
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .put(`${state.pathCommonConfig}`, payload)
                return response;
            }catch (error){
                return error;
            }
        },
        async saveConfig({ state }, payload){
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .post(`${state.pathCommonConfig}`, payload)
                return response;
            }catch (error){
                return error;
            }
        }
    }
}