<template>
  <div>
    <HeaderContent :label="$t('title.adAdd')" :list="items" />
    <div class="mt-4" />
    <FormAd
      :ad="ad"
      @onSubmit="onSubmit"
      :labelButton="$t('button.adAdd')"
      :loading="loading"
      status="create"
    />
    <v-snackbar top right v-model="alertSuccess" color="success">
      Post Success
    </v-snackbar>
    <v-snackbar top right v-model="alertError" color="error">
      {{errorMessage}}
    </v-snackbar>
  </div>
</template>

<script>
import FormAd from "../../../containers/Form/formAd";
import HeaderContent from "../../../containers/HeaderContent";
import { mapActions } from "vuex";

export default {
  components: {
    HeaderContent,
    FormAd,
  },
  data() {
    return {
      loading: false,
      alertSuccess: false,
      alertError: false,
      errorMessage: 'Post Failed',
      ad: {
        adTitle: "",
        duration: "",
        medias: [],
        adCampaignType : '',
        accountIds: [],
        accountType: '',
        isOfficial: ''
      },
      image: "",
      status: "",
      items: [
        {
          text: "Manage Advertising",
          disabled: false,
          href: "/post/ad",
        },
        {
          text: "Buat Advertising",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      createAd: "ad/createAd",
    }),
    async onSubmit() {
      this.loading = true;
      const response = await this.createAd(this.ad);
      if (response.status === 201) {
        this.alertSuccess = true;
        setTimeout(() => {
          this.$router.push("/post/ad");
          this.alertSuccess = false;
        }, 500);
        this.loading = false;
      } else {
        const responseString = JSON.stringify(response, null, 2);
        if(responseString.includes('status code 400')){
          this.errorMessage = 'Kamu belum pernah memposting video,jadi kamu tidak dapat memasang iklan.'
        }
        this.loading = false;
        this.alertError = true;
        setTimeout(() => {
          this.alertError = false;
        }, 3000);
      }
    },
  },
};
</script>
