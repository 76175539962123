export default {
  namespaced: true,
  state: {
    pathAd: "advertising",
    currentPage: 1,
  },
  mutations: {
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
  },
  actions: {
    async getListAd({ state }, payload) {
      let response;
      try {
        response = await this._vm.$httpWithToken().get(state.pathAd, {
          params: {
            page: payload.page,
          },
        });
        return response;
      } catch (error) {
        return error;
      }
    },
    async createAd({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .post(state.pathAd, payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async deleteAd({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .delete(`${state.pathAd}/${payload}`);
        return response;
      } catch (error) {
        return error;
      }
    },
    async updateAdStatus({ state }, payload) {
      let response;
      try {
        response = await this._vm
            .$httpWithToken()
            .put(`${state.pathAd}/${payload.id}/status`, null, {
                  params: {
                    status: payload.status,
                  },
                }
            );
        return response;
      } catch (error) {
        return error;
      }
    },
    async editAd({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .put(`${state.pathAd}/${payload.id}`, payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async searchAd({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .get(`${state.pathAd}`, {
            params: {
              page: payload.page,
              adTitle: payload.search,
              size: payload.size,
            },
          });
        return response;
      } catch (error) {
        return error;
      }
    },
    async getAdById({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .get(`${state.pathAd}/${payload}`);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};
