export default {
    namespaced: true,
    state: {
        pathReport: "/reportRelationship",
        paramsDate: [],
        displayDate: "",
        isFilterable : false,
        keywordSearch: "",
    },
    mutations: {
        setParamsDate(state, payload) {
            state.paramsDate = payload;
        },
        setDisplayDate(state, payload) {
            state.displayDate = payload;
        },
        setIsFilterable (state, payload) {
            state.isFilterable = payload
        },
        setReporterName(state, payload) {
            state.reporterName = payload;
        },
        setReportedName(state, payload) {
            state.reportedName = payload;
        },
    },
    actions: {
        async getReportRelationshipsList({ state }, payload){
            let response;
            try {
                response = await this._vm
                    .$httpWithToken()
                    .post(`${state.pathReport}/list`,
                        {
                            reporterName:payload.reporterName,
                            reportedName:payload.reportedName,
                            messageType: payload.messageType,
                            startTime:payload.startTime,
                            endTime:payload.endTime,
                            reportContent:payload.reportContent,
                        },{
                            params: {
                                pageSize: payload.pageSize,
                                pageNum: payload.pageNum
                            }
                        }
                    )
                return response
            } catch (error){
                return error;
            }
        },
    }
}